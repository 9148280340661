import React from 'react'

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div >
          <div className="footertext" style={{paddingTop:"20px",marginTop:"20px", textAlign:"center"}}>
            <p>B-47 Cherki, Gaya, Bihar INDIA | Phone: +91 9716-887-448 | Email: hodaelectricals2017@gmail.com</p>
            <p>Copyright © 2023 Hoda Electricals</p>
          </div>
        </div>
      </div>
    </>
  );
}